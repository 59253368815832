<template>
  <div class="app-container">
    <CrudTable
      entity="OrderHistory"
      :columns="columns"
      :permission-code="'OrderHistory'"
      :allow-create="false"
      :allow-actions="false"
      :allow-delete="false"
      :on-row-dbl-click="() => {}"
    />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { toDateStringVisual, toString } from '@/utils/date';

export const deliveryMethodColumnFilter = vue => ({
  type: 'select',
  options: [
    { value: 0, label: vue.$i18n.t('deliveryMethod.delivery') },
    { value: 1, label: vue.$i18n.t('deliveryMethod.takeOut') },
    { value: 2, label: vue.$i18n.t('deliveryMethod.dining') }
  ],
  valueField: 'value',
  labelField: 'label'
});
export const deliveryMethodColumnFormatter = vue => (row, column, cellValue) => {
  switch (cellValue) {
    case 1:
      return vue.$i18n.t('deliveryMethod.takeOut');
    case 2:
      return vue.$i18n.t('deliveryMethod.dining');
    default:
      return vue.$i18n.t('deliveryMethod.delivery');
  }
};
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        { field: 'orderDate', label: 'common.date', formatter: this.formatDate, filter: { type: 'date' } },
        { field: 'ticketNumber', label: 'common.ticketNumber' },
        { field: 'postcode', label: 'common.postalCode' },
        'houseNumber',
        'deliveryPerson',
        {
          field: 'deliveryMethod',
          label: 'common.deliveryMethod',
          filter: deliveryMethodColumnFilter(this),
          formatter: deliveryMethodColumnFormatter(this)
        },
        {
          field: 'createdAt',
          label: 'common.timeCreated',
          formatter: this.formatTime,
          filter: { type: 'none' },
          sortable: false
        },
        {
          field: 'expectedDeliveryTime',
          label: 'common.timeExpectedDelivery',
          formatter: this.formatTime,
          filter: { type: 'none' },
          sortable: false
        },
        {
          field: 'inStoreAt',
          label: 'common.timeInstore',
          formatter: this.formatTime,
          filter: { type: 'none' },
          sortable: false
        },
        {
          field: 'leftStoreAt',
          label: 'common.timeLeftStore',
          formatter: this.formatTime,
          filter: { type: 'none' },
          sortable: false
        },
        {
          field: 'deliveredAt',
          label: 'common.timeDelivered',
          formatter: this.formatTime,
          filter: { type: 'none' },
          sortable: false
        },
        {
          field: 'waitingTime',
          label: 'common.timeWaited',
          filter: { type: 'none' },
          formatter: this.formatTimeSpan,
          sortable: false
        }
      ]
    };
  },
  methods: {
    formatDate(row, column, cellValue) {
      return toDateStringVisual(cellValue);
    },
    formatTime(row, column, cellValue) {
      return toString(cellValue, 'HH:mm');
    },
    formatTimeSpan(row, column, cellValue) {
      if (!cellValue) {
        return null;
      }
      const hours = cellValue.value.hours;
      const minutes = cellValue.value.minutes;
      const seconds = cellValue.value.seconds;
      const tooEarly = hours < 0 || minutes < 0 || seconds < 0;
      return (
        cellValue &&
        `${tooEarly ? '- ' : ''}${Math.abs(hours)}:${`0${Math.abs(minutes)}`.slice(-2)}:${`0${Math.abs(seconds)}`.slice(
          -2
        )}`
      );
    }
  }
};
</script>
